import React from 'react';
import { seoDataCategoriesRoutes, appRoutes, updatedRoutesWithTrailingSlashIfNeeded } from '../../config/app_routes';
import { isKA } from '../../../common/config/utils';

import shared from '../../main.module.scss';

window.shared = shared;

const Advisor = require('../advisor').default;
const HomePage = require('../home_page').default;

const SearchResults = React.lazy(() => import('../search_results'));
const MyOrders = React.lazy(() => import('../my_orders'));
const AdvisorOrders = React.lazy(() => import('../advisor_orders'));
const OrderDetails = React.lazy(() => import('../order_details'));
const InviteFriends = React.lazy(() => import('../invite_friends'));
const AddFunds = React.lazy(() => import('../add_funds'));
const PromoCode = React.lazy(() => import('../promo_code'));
const Transcript = React.lazy(() => import('../chat_transcript'));
const Settings = React.lazy(() => import('../settings'));
const Cashback = React.lazy(() => import('../cashback'));
const PaymentSources = React.lazy(() => import('../payment_sources'));
const EmptyPreload = React.lazy(() => import('../empty_preload/empty_preload'));
const SpendLimitsVerifications = React.lazy(() => import('../spend_limits_verification'));
const SignInPage = React.lazy(() => import('../signin_page'));
const PurchaseDetailsForMobileApp = React.lazy(() => import('../purchase_details_for_mobile_app'));
const SeoDataPage = React.lazy(() => import('../shared/seo_data_page'));
const Categories = React.lazy(() => import('../categories'));
const NotificationPreferences = React.lazy(() => import('../settings/notification_preferences'));

const getAppRoutes = () => {
  const routes = {
    [appRoutes.home]: {
      Component: HomePage,
      withoutSuspense: true
    },
    [appRoutes.psychics]: {
      Component: SearchResults
    },
    [appRoutes.advisor]: {
      Component: Advisor,
      withoutSuspense: true
    },
    [appRoutes.myOrders]: {
      Component: MyOrders
    },
    [appRoutes.advisorOrders]: {
      Component: AdvisorOrders
    },
    [appRoutes.orderDetails]: {
      Component: OrderDetails
    },
    [appRoutes.inviteFriends]: {
      Component: InviteFriends
    },
    [appRoutes.addFunds]: {
      Component: AddFunds
    },
    [appRoutes.promoCode]: {
      Component: PromoCode
    },
    [appRoutes.transcript]: {
      Component: Transcript
    },
    [appRoutes.settings]: {
      Component: Settings
    },
    [appRoutes.cashback]: {
      Component: Cashback
    },
    [appRoutes.paymentSources]: {
      Component: PaymentSources
    },
    [appRoutes.emptyPreload]: {
      Component: EmptyPreload
    },
    [appRoutes.spendLimits]: {
      Component: SpendLimitsVerifications
    },
    [appRoutes.register]: {
      Component: SignInPage
    },
    [appRoutes.signin]: {
      Component: SignInPage
    },
    [appRoutes.purchaseDetails]: {
      Component: PurchaseDetailsForMobileApp
    },
    [appRoutes.specialities]: {
      Component: Categories
    },
    [appRoutes.notificationPreferences]: {
      Component: NotificationPreferences
    }
  };

  return routes;
};

const getCategoryRoutes = () => {
  let categoryRoutes;
  if (isKA()) {
    const { categoryRoutesKA } = require('./category_routes/category_routes_ka');
    categoryRoutes = categoryRoutesKA;
  } else {
    const { categoryRoutesPG } = require('./category_routes/category_routes_pg');
    categoryRoutes = categoryRoutesPG;
  }

  return categoryRoutes;
};

const getSectionRoutes = () => {
  let sectionRoutes;
  if (isKA()) {
    const { sectionRoutesKA } = require('./section_routes/section_routes_ka');
    sectionRoutes = sectionRoutesKA;
  } else {
    const { sectionRoutesPG } = require('./section_routes/section_routes_pg');
    sectionRoutes = sectionRoutesPG;
  }

  return sectionRoutes;
};

const getSeoDataCategoriesRoutes = () => {
  const routes = seoDataCategoriesRoutes();
  let seoDataRoutes = {};
  if (!routes) return seoDataCategoriesRoutes;
  seoDataRoutes = {
    [seoDataCategoriesRoutes()]: {
      Component: SeoDataPage
    }
  };
  return seoDataRoutes;
};

export const applicationRoutes = {
  ...updatedRoutesWithTrailingSlashIfNeeded({ routes: getAppRoutes() }),
  ...updatedRoutesWithTrailingSlashIfNeeded({ routes: getSectionRoutes() }),
  ...updatedRoutesWithTrailingSlashIfNeeded({ routes: getCategoryRoutes() }),
  ...updatedRoutesWithTrailingSlashIfNeeded({ routes: getSeoDataCategoriesRoutes() })
};
