import React from 'react';

const SearchResults = React.lazy(() => import('../../search_results'));

// "/favorite-advisors",
// "/trending-psychics",
// "/highest-rated-psychics",
// "/recommended-psychics",
// "/most-accurate-psychics"

export const sectionRoutesPG = {
  '/favorite-advisors': {
    Component: SearchResults
  },
  '/trending-psychics': {
    Component: SearchResults
  },
  '/highest-rated-psychics': {
    Component: SearchResults
  },
  '/recommended-psychics': {
    Component: SearchResults
  },
  '/most-accurate-psychics': {
    Component: SearchResults
  }
};
