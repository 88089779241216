import React from 'react';

const SearchResults = React.lazy(() => import('../../search_results'));

// "/favorite-advisors",
// "/soulmate-advisors",
// "/tension-relationship-advisors",
// "/path-heading-advisors",
// "/love-readings-advisors"

export const sectionRoutesKA = {
  '/favorite-advisors': {
    Component: SearchResults
  },
  '/soulmate-advisors': {
    Component: SearchResults
  },
  '/tension-relationship-advisors': {
    Component: SearchResults
  },
  '/path-heading-advisors': {
    Component: SearchResults
  },
  '/love-readings-advisors': {
    Component: SearchResults
  },
  '/my-soulmate': {
    Component: SearchResults
  },
  '/right-path': {
    Component: SearchResults
  },
  '/our-tense-relationship': {
    Component: SearchResults
  },
  '/my-tarot-truth': {
    Component: SearchResults
  }
};
