import React, { Suspense } from 'react';
import {
  BrowserRouter, Route, useParams, useLocation, useMatch, useNavigate
} from 'react-router-dom';
import PropTypes from 'prop-types';
import { LastLocationProvider } from 'react-router-dom-last-location';
import LocalizedSwitch from './localized_switch';
import LocalizedRouter from './localized_router';
import BugsnagErrorBoundary from '../../config/bugsnag';
import UnregisteredLinks from './unregistered_link';
import { applicationRoutes } from './routes_settings';
import { generateLocalizedPaths } from './routes_utils';
import { appLinks } from '../../config/const';

import Popover from '../popover';
import AnalyticsAndCommonLogicComponent from '../analytics';
import GeneralPopup from '../general_popup';
import CookiesPopover from '../../../common/cookies_popover';
import Registration from '../registration';
import NotifyMe from '../notify_me';
import MessageToApp from '../message_to_app';

const Overlays = React.lazy(() => import('./overlays/overlays'));
const VoipChatOverlay = React.lazy(() => import('../voip_chat_overlay'));
const ChatOverlay = React.lazy(() => import('../chat_overlay'));

const suspenseComponent = (component) => <Suspense>{ component }</Suspense>;

function RenderRoute() {
  const getRoutes = () => {
    const routes = [];
    Object.keys(applicationRoutes).forEach((key) => {
      const path = `${ key }`;
      const { Component, withoutSuspense } = applicationRoutes[key];
      if (!path || path === 'null') {
        routes.push(<Route key="null" />);
      } else {
        const r = [path, ...generateLocalizedPaths({ path })].map((p) => {
          const location = useLocation();
          const params = useParams();
          const match = useMatch(`${ p }`, { end: true });
          const history = useNavigate();
          return (
            <Route
              key={ p }
              exact
              path={ p }
              element={ (
                <LastLocationProvider>
                  {
                    withoutSuspense
                      ? <Component location={ location } params={ params } match={ match } history={ history } />
                      : suspenseComponent(<Component location={ location } params={ params } match={ match } history={ history } />)
                  }
                </LastLocationProvider>
            ) }
            />
          );
        });
        routes.push(...r);
      }
    });
    return routes;
  };

  const routes = getRoutes();

  return (
    <UnregisteredLinks>
      <LocalizedSwitch>
        { routes.map((r) => r) }
      </LocalizedSwitch>
    </UnregisteredLinks>
  );
}

function RoutesComponent({
  state, voipState, popoverVisibility
}) {
  return (
    <BugsnagErrorBoundary>
      <div suppressHydrationWarning id="layout" style={ { width:'100%', backgroundColor: 'var(--app-background-color)' } }>
        <BrowserRouter>
          <LocalizedRouter basename="/">
            <RenderRoute />
          </LocalizedRouter>
          {suspenseComponent(<Overlays />)}
          { state ? suspenseComponent(<ChatOverlay />) : null }
          { voipState ? suspenseComponent(<VoipChatOverlay />) : null }
          { popoverVisibility ? <Popover /> : null}
          <AnalyticsAndCommonLogicComponent />
          <GeneralPopup />
          <CookiesPopover
            wpccMessageText="We use cookies to ensure you get the best experience on our website."
            wpccPrivacyText="More info."
            wpccPrivacyHref={ appLinks.privacyPolicy }
            wpccBtnText="Accept"
          />
          <Registration />
          <NotifyMe />
          <MessageToApp />
        </BrowserRouter>
      </div>
    </BugsnagErrorBoundary>
  );
}

RoutesComponent.propTypes = {
  state: PropTypes.string,
  errorCode: PropTypes.number,
  errorUrl: PropTypes.string,
  clearAplicationError: PropTypes.func.isRequired,
  logOutClear: PropTypes.func.isRequired,
  displayRegister: PropTypes.func.isRequired,
  voipState: PropTypes.string,
  popoverVisibility: PropTypes.bool
};

RoutesComponent.defaultProps = {
  state: null,
  errorCode: null,
  voipState: null,
  errorUrl: null,
  popoverVisibility: false
};

export default RoutesComponent;
